<template>
    <dashboard-layout>
        <div class="content-area__body">
            <!-- personal information section -->
            <section class="personal-information-section section-gap-30">
                <div class="secondary-sidebar">
                    <div v-on-click-away="hideMenu" class="secondary-sidebar-heading gutter-10 cursor-pointer" :class="{'hamburger-button-active' : showMenu}" v-on:click="showMenu = !showMenu">
                        <div class="section-title text-capitalize">{{ $t("my profile")}}</div>
                    </div>
                    <div class="secondary-sidebar-body gutter-10" :class="{'hamburger-menu-show': showMenu}">
                        <ul class="p-info__nav">
                            <li class="p-info__nav__item ">
                                <router-link :to="{name : 'candidate.basic-info'}" class="p-info__nav__link text-capitalize" exact exact-active-class="active">{{ $t("basic information")}}</router-link>
                            </li>
                            <li class="p-info__nav__item">
                                <router-link :to="{name : 'candidate.qualifications'}" class="p-info__nav__link text-capitalize" exact exact-active-class="active">{{ $t("Educational Qualification")}}</router-link>
                            </li>
                            <li class="p-info__nav__item">
                                <router-link :to="{name : 'candidate.employments'}" class="p-info__nav__link text-capitalize" exact exact-active-class="active">{{ $t("Employment History")}}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <router-view></router-view>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>
    import DashboardLayout from "./DashboardLayout";

    export default {
        components: {DashboardLayout},
        data() {
            return {
                showMenu: false
            }
        },
        methods: {
            hideMenu() {
                this.showMenu = false;
            }
        }
    }
</script>

<style scoped>
    .personal-information-section .p-info-content {
        position:unset !important;
    }
    .personal-information-section .secondary-sidebar-body .p-info__nav {
        list-style: none;
    }
</style>
